<template>
  <div class="pz__entity-selector tw-mt-4">
    <div class="tw-mb-4 tw-flex tw-justify-between">
      <h4 class="mb-0 tw-text-body">{{ $t(title) }}</h4>
      <span
        v-if="collapsable"
        class="tw-cursor-pointer"
        @click="$emit('collapse-change', collapsed)"
      >
        <small>
          {{ !collapsed ? $t('Hide') : $t('Show') }}
        </small>
        <feather-icon
          :icon="!collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="tw-ml-2"
        />
      </span>
    </div>
    <div
      v-show="!collapsed"
      class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-relative"
    >
      <div v-if="viewMode" class="selectors__backdrop tw-absolute" />
      <label
        v-for="(item, i) in uniqueOption"
        :key="i"
        class="tw-flex tw-items-center tw-text-xs tw-cursor-pointer tw-mb-5"
        @click="$emit('item-click', item)"
      >
        <feather-icon
          :icon="isSelected(item) ? 'CheckSquareIcon' : 'SquareIcon'"
          :class="{ 'tw-text-primary': isSelected(item) }"
        />
        <feather-icon
          :icon="EntityIcon[item.id] || 'CircleIcon'"
          class="tw-mx-3"
        />
        <span>
          {{ $t(item.label) }}
        </span>
      </label>
    </div>
    <div
      v-show="collapsed"
      class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg"
    >
      <slot name="collapsed-content" />
    </div>
  </div>
</template>

<script>
import { EntityIcon } from '@/constants/app'

export default {
  props: {
    title: {
      type: String,
      default() {
        return 'Tables'
      },
    },

    collapsable: {
      type: Boolean,
      default: false,
    },

    collapsed: {
      type: Boolean,
      default: false,
    },

    viewMode: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      required: true,
    },

    selections: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      EntityIcon,
    }
  },

  computed: {
    uniqueOption() {
      return this.$lodash.uniqBy(this.options, 'label')
    },
  },
  methods: {
    isSelected({ id }) {
      return this.selections.includes(id)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.pz__entity-selector {
  width: 100%;

  .column__content {
    background: var(--colour--card-bg);
  }

  .selectors__backdrop {
    inset: 0;
    background: rgba($colour--black-light, 0.2);
    z-index: 3;
  }
}
</style>
