import { Schema } from '@/constants/app'
import { toPerspective } from '@/helpers/customEntity'
import { mapMutations } from 'vuex'

/**
 * !! ATTENTION !!
 * Only use `prozessEntitySelector` mixin if you're 100% confident in using mixins.
 *
 * Abuse of mixin usage will cause unexpected behavior and difficulty in code maintenance.
 *
 *
 * Do not modify this mixin unless you're 100% sure of what you're doing.
 * In case you can't avoid modifying this mixin,
 * MAKE SURE your WILL NOT AFFECT the Components implementing this mixin.
 *
 * DO NOT DELETE THIS COMMENT.
 */

export default store => ({
  methods: {
    ...mapMutations({
      addFieldOption: `${store}/ADD_FIELD_OPTION`,
      addSelectedEntity: `${store}/ADD_SELECTED_ENTITY`,
      addSelectedField: `${store}/ADD_SELECTED_FIELD`,
      setEntities: `${store}/SET_ENTITIES`,
      setSelectedEntities: `${store}/SET_SELECTED_ENTITIES`,
      removeFieldOption: `${store}/REMOVE_FIELD_OPTION`,
      resetFieldOption: `${store}/RESET_FIELD_OPTION`,
      removeSelectedEntity: `${store}/REMOVE_SELECTED_ENTITY`,
      resetStore: `${store}/RESET_STORE`,
      toggleCollapsedState: `${store}/TOGGLE_OPTION_COLLAPSED_STATE`,
    }),

    filterEntityOptions(entity) {
      const { value: relationships } = toPerspective(
        entity.key,
        entity.relationships,
        [],
        { hideBuiltIn: false },
      )
      const defaultOptions = this.getDefaultEntityOptions(entity).filter(eeeee => eeeee.label != null)

      const addedEntityOptions = [
        entity,
        ...defaultOptions,
        ...relationships.reduce((relatedEntities, rel) => {
          const foundEntity = this.entities.find(_entity => {
            const customEntityId = `${rel.schema2}.${rel.key2}`
            const customEntityId2 = `${rel.schema1}.${rel.key1}`
            return (
              _entity.id === customEntityId
              && !defaultOptions.some(option => option.id === customEntityId)
            )
          })

          if (foundEntity) {
            relatedEntities.push(foundEntity)
          }

          return relatedEntities
        }, []),
      ]
      if (!this.selectedEntities.length) {
         this.entityOptions = []
      }
      this.entityOptions.push(...addedEntityOptions.filter(
         e => {
            const entityIndex = this.entityOptions.findIndex(v => v.id === e.id)
            return entityIndex < 0
         },
      ))
    },

    getDefaultEntityOptions({ schema, id }) {
      const defaultEntityOptions = {
        'custom_schema.salg': this.entities.filter(_entity => ['crm.company'].includes(_entity.id)),
        'crm.company': this.entities.filter(_entity => ['crm.contact', 'notes.note', 'core.inquiry', 'custom_schema.salg', 'custom_schema.idd_kontakt_2', 'custom_schema.idd_kontakt', 'documents.document'].includes(_entity.id)),
        'crm.contact': this.entities.filter(_entity => ['crm.company', 'notes.note', 'core.inquiry', 'custom_schema.idd_selskap', 'documents.document'].includes(_entity.id)),
        'documents.document': this.entities.filter(_entity => ['crm.company', 'crm.contact'].includes(_entity.id)),
        'notes.note': this.entities.filter(_entity => ['crm.company', 'crm.contact', 'core.inquiry'].includes(_entity.id)),
        'core.inquiry': this.entities.filter(_entity => ['crm.company', 'crm.contact', 'notes.note'].includes(_entity.id)),
      }

      const enityConnected = []
      this.entities.forEach(en => {
        en.relationships.forEach(enRel => {
          const rel1 = `${enRel.schema1}.${enRel.key1}`
          const rel2 = `${enRel.schema2}.${enRel.key2}`

          if (id === rel1 && !enityConnected.includes(rel2)) {
            enityConnected.push(rel2)
          } else if (id === rel2 && !enityConnected.includes(rel1)) {
            enityConnected.push(rel1)
          }
        })
      })

      const entityOptions = this.entities.filter(_entity => enityConnected.includes(_entity.id))
      if (defaultEntityOptions[id]) {
        entityOptions.push(...defaultEntityOptions[id])
      }
      if (schema === Schema.CUSTOM) {
        entityOptions.push(...this.entities.filter(_entity => ['documents.document', 'notes.note'].includes(_entity.id)))
      }
      return entityOptions
    },

    generateNewBaseEntityOptions(newBaseEntity) {
      const selectedEntitiesCopy = this.$lodash.cloneDeep(
        this.selectedEntities,
      )

      this.filterEntityOptions(
        this.entities.find(__entity => __entity.id === newBaseEntity),
      )

      /**
       * Reselect last selected options that are still present on the new entityOptions
       */
      this.selectedEntities.forEach(selection => {
        const canBeSelected = this.entityOptions.some(
          option => option.id === selection,
        )

        /**
         * Clean selectedEntitiesCopy:
         * If the last selected item is not present on the new entityOptions, remove it from the selectedEntitiesCopy
         */
        if (!canBeSelected) {
          const index = selectedEntitiesCopy.indexOf(selection)
          selectedEntitiesCopy.splice(index, 1)
          this.removeFieldOption(selection)
        }

        /**
         * Once selectedEntitiesCopy is fully cleaned, set it to be the new selectedEntities
         */
        this.setSelectedEntities(this.$lodash.cloneDeep(selectedEntitiesCopy))
      })

      return false
    },

    handleTableClick(entity) {
      const { id } = entity

      if (this.selectedEntities.includes(id)) {
        const index = this.selectedEntities.indexOf(id)
        this.removeSelectedEntity(index)
        this.removeFieldOption(id)

        if (!this.selectedEntities.length) {
          this.resetEntityOptions()
        } else {
          this.resetFieldOption()
          this.entityOptions = []
          this.selectedEntities.forEach(
            e => {
               const targetEntity = this.entities.find(en => en.id === e)
               this.filterEntityOptions(targetEntity)
               this.addFieldOption(e)
            },
         )
        }
      } else {
        this.filterEntityOptions(entity)
        this.addSelectedEntity(id)
        this.addFieldOption(id)
      }
    },

    resetEntityOptions() {
      this.entityOptions = this.$lodash.cloneDeep(this.entities)
    },
  },
})
