<template>
  <div class="pz__entity-field-selector tw-mt-4">
    <div class="tw-mb-4 tw-flex tw-justify-between">
      <h4 class="mb-0 tw-text-body">{{ $t(title) }}</h4>
      <span
        v-if="collapsable"
        class="tw-cursor-pointer"
        @click="$emit('collapse-change', collapsed)"
      >
        <small>
          {{ !collapsed ? $t('Hide') : $t('Show') }}
        </small>
        <feather-icon
          :icon="!collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="tw-ml-2"
        />
      </span>
    </div>
    <div class="column__content tw-rounded-lg">
      <div
        v-show="!collapsed"
        class=" column__content tw-overflow-auto tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-relative"
      >
        <div v-if="viewMode" class="selectors__backdrop tw-absolute" />
        <div
          v-if="!options.length"
          class="tw-text-lg tw-text-center tw-text-muted tw-mt-5"
        >
          {{ $t('No Fields Available') }}
        </div>
        <ProzessEntityFieldControl
          v-for="(item, i) in options"
          :key="i"
          :toggleable="toggleable"
          :toggle-text="toggleText"
          :store="store"
          :entity-id="item.entityId"
          :disabled="viewMode"
        />
      </div>
    </div>
    <div
      v-show="collapsed"
      class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg"
    >
      <slot name="collapsed-content" />
    </div>
  </div>
</template>

<script>
import ProzessEntityFieldControl from '@/components/shared/ProzessEntityFieldControl.vue'

export default {
  components: {
    ProzessEntityFieldControl,
  },

  props: {
    title: {
      type: String,
      default() {
        return 'Fields'
      },
    },

    store: {
      type: String,
      required: true,
    },

    collapsable: {
      type: Boolean,
      default: false,
    },

    collapsed: {
      type: Boolean,
      default: false,
    },

    viewMode: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      required: true,
    },

    toggleable: {
      type: Boolean,
      default: false,
    },

    toggleText: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.pz__entity-field-selector {
  width: 100%;

  .column__content {
    background: var(--colour--card-bg);
  }

  .selectors__backdrop {
    inset: 0;
    background: rgba($colour--black-light, 0.2);
    z-index: 3;
  }
}
</style>
